import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SmallBanner from "../components/smallBanner";
import ContactFormOffer from "../components/contactFormOffer";

const OfferPage = () => {
	const lang = "pl";

	return (
		<Layout
			seoTitle="Oferta"
			lang={lang}
			translationEN="/en/our-services/"
			translationDE="/de/angebot/"
		>
			<SmallBanner title="Oferta" lang={lang} />
			<section className="offer-products">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
							<h2 className="color--main mb-100">Produkty</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6 col-lg-3 offer-products__product-holder">
							<Link
								className="offer-products__item"
								to="/oferta/"
							>
								<img
									className="item__image"
									src={require("../assets/img/palete.png")}
									alt="Paletten"
								/>
								<p className="bold small">Palety</p>
								<p className="item__title big">EUR EPAL</p>
							</Link>
						</div>
						<div className="col-12 col-md-6 col-lg-3 offer-products__product-holder">
							<Link
								className="offer-products__item"
								to="/oferta/"
							>
								<img
									className="item__image"
									src={require("../assets/img/palete2.png")}
									alt="Paletten"
								/>
								<p className="bold small">Palety</p>
								<p className="item__title big">CHEMICZNE CP</p>
							</Link>
						</div>
						<div className="col-12 col-md-6 col-lg-3 offer-products__product-holder">
							<Link
								className="offer-products__item"
								to="/oferta/"
							>
								<img
									className="item__image"
									src={require("../assets/img/palete3.png")}
									alt="Paletten"
								/>
								<p className="bold small">Palety</p>
								<p className="item__title big">SPECJALNE</p>
							</Link>
						</div>
						<div className="col-12 col-md-6 col-lg-3 offer-products__product-holder">
							<Link
								className="offer-products__item"
								to="/oferta/"
							>
								<img
									className="item__image"
									src={require("../assets/img/palete4.png")}
									alt="Paletten"
								/>
								<p className="bold small">Palety</p>
								<p className="item__title">DÜSSELDORF</p>
							</Link>
						</div>
						<div className="col-12 col-md-6 col-lg-3 offer-products__product-holder">
							<Link
								className="offer-products__item"
								to="/oferta/paleta-4-0/"
							>
								<img
									className="item__image"
									src={require("../assets/img/palete5.png")}
									alt="Paletten"
								/>
								<p className="bold small">Palety</p>
								<p className="item__title">PALETA 4.0</p>
							</Link>
						</div>
					</div>
				</div>
			</section>
			<section className="offer-services">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
							<h2 className="color--main mb-40">Usługi</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../assets/img/offer-icon1.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Obróbka termiczna palet (HT)
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Jej przeprowadzenie umożliwi Ci eksport
										towarów na palecie na cały świat.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../assets/img/offer-icon2.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Suszenie palet
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Ten proces pozwala zapobiec rozwojowi w
										drewnie pleśni i grzybów.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../assets/img/offer-icon3.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Znakowanie palet poprzez wypalanie
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Uzyskaj gwarancję trwałości oznaczeń i
										możliwość dostosowania ich do wymagań
										swoich lub klienta.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../assets/img/offer-icon4.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Znakowanie palet poprzez barwienie elementów
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Dzięki malowaniu możesz wyróżnić i
										zidentyfikować swoją paletę wśród
										wszystkich pool'i paletowych.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../assets/img/offer-icon5.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Stosowanie elementów metalowych w
									konstrukcji palet
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Zwiększ wytrzymałość palety lub
										przystosuj ją do montażu skrzyni lub
										innej konstrukcji.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../assets/img/offer-icon6.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Projektowanie palet według indywidualnych
									potrzeb
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Dostosuj paletę do swojej linii
										produkcyjnej, rodzaju towaru i warunków
										transportu.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../assets/img/offer-icon7.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Magazynowanie i utrzymywanie stanów
									magazynowych
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Uzyskaj nieprzerwany dostęp do swoich
										palet bez poświęcania miejsca
										magazynowego.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../assets/img/offer-icon8.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Indywidualne plany dostaw, w tym dostawy
									just-in-time
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Dostosuj ilość dostaw do swoich
										bieżących potrzeb i zoptymalizuj swoje
										powierzchnie magazynowe.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../assets/img/offer-icon9.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Spinanie taśmą
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Dzięki spięciu słupków taśmą, masz
										pewność, że palety dojadą do Ciebie w
										doskonałym stanie.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../assets/img/offer-icon10.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Znakowanie palet przez nadruk
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Dostosujemy oznaczenia na palecie do
										Twoich indywidualnych potrzeb poprzez
										nadruk dynamicznych symboli np. numeru
										partii lub daty produkcji.
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../assets/img/offer-icon11.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Impregnacja preparatem przeciw siniźnie
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Możesz uzyskać paletę dodatkowo
										zabezpieczoną przeciwko pleśnieniu w
										krytycznych dla drewna okresach (późna
										wiosna, lato).
									</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6 col-lg-3">
							<div className="offer-services__item">
								<img
									className="item__icon"
									src={require("../assets/img/offer-icon12.svg")}
									alt="Obróbka termiczna palet"
								/>
								<p className="bold item__title">
									Inteligentne palety
								</p>
								<div className="item__hover-content">
									<p className="item__text">
										Możesz zintegrować paletę z otoczeniem
										cyfrowym Twojej firmy dzięki
										zastosowaniu tagów RFID.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="offer-quality">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
							<h2 className="color--main mb-40">Jakość</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
							<p className="mb-100">
								W PalettenWerk obowiązuje 6-etapowy system
								kontroli jakości, który stanowi gwarancję
								powtarzalności, wytrzymałości oraz długotrwałej
								użyteczności produkowanych palet. System
								obejmuje kontrolę dostaw elementów składowych
								palet, kontrolę procesu produkcyjnego oraz
								produktów gotowych. Wszystkie procesy kontrolne
								zintegrowane są w ramach systemu zarządzania
								jakością.
							</p>
						</div>
						<div className="col-12 offer-quality__btn-holder">
							<Link
								className="btn"
								to="/oferta/jakosc/"
								data-hover="Zobacz więcej"
							>
								Zobacz więcej
								<span className="btn-arrows"></span>
							</Link>
						</div>
					</div>
				</div>
			</section>
			<section className="offer-delivery">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
							<h2 className="color--main mb-40">Dostawa</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
							<p className="mb-100">
								PalettenWerk gwarantuje Klientom szybkie i
								terminowe dostawy palet na terenie całej Europy.
								Realizacja zamówień na standardowe palety w
								Polsce odbywa się w ciągu 24 godzin, a dostawy
								do pozostałych krajów realizowane są do 3 dni
								roboczych od momentu złożenia zamówienia.
							</p>
						</div>
						<div className="col-12 offer-delivery__btn-holder">
							<Link
								className="btn"
								to="/oferta/dostawa/"
								data-hover="Zobacz więcej"
							>
								Zobacz więcej
								<span className="btn-arrows"></span>
							</Link>
						</div>
					</div>
				</div>
			</section>
			<div className="col-lg-10 contact_formular">
				<h3>Formularz kontaktowy</h3>
				<ContactFormOffer lang={lang} />
			</div>
		</Layout>
	);
};

export default OfferPage;
